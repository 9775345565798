/* eslint-disable max-len */
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { DialogBoxContent } from '../DialogBoxContent/DialogBoxContent';
import { Loader } from '../Loader/Loader';

interface RemoveVendorModalProps {
    isShowDeleteVendor: boolean;
    isLoadingDeleteVendor: boolean;
    title: string;
    propertyName: string;
    vendorType: string;
    vendorName: string;
    buttonText?: string;
    onCloseDeleteVendor: () => void;
    onSubmitDeleteVendor: () => void;
}

export default memo(
    ({
        isShowDeleteVendor,
        title,
        propertyName,
        vendorType,
        vendorName,
        buttonText,
        onCloseDeleteVendor,
        onSubmitDeleteVendor,
        isLoadingDeleteVendor,
    }: RemoveVendorModalProps) => {
        const { t } = useTranslation();

        const cancelDeleteVendor = useCallback(() => {
            if (!isLoadingDeleteVendor) {
                onCloseDeleteVendor();
            }
        }, [isLoadingDeleteVendor, onCloseDeleteVendor]);

        const submitDeleteVendor = useCallback(() => {
            if (!isLoadingDeleteVendor) {
                onSubmitDeleteVendor();
            }
        }, [isLoadingDeleteVendor, onSubmitDeleteVendor]);

        return (
            isShowDeleteVendor && (
                <Dialog open>
                    <DialogBoxContent
                        onClose={cancelDeleteVendor}
                        buttons={
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                gap="10px"
                            >
                                <Button onClick={cancelDeleteVendor}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={
                                        isLoadingDeleteVendor && (
                                            <Loader color="inherit" />
                                        )
                                    }
                                    disabled={isLoadingDeleteVendor}
                                    onClick={submitDeleteVendor}
                                    color="error"
                                >
                                    {buttonText}
                                </Button>
                            </Box>
                        }
                    >
                        <Box maxWidth="400px">
                            <Typography
                                mb="20px"
                                typography="poppins.subtitle1"
                            >
                                {title}
                            </Typography>
                            <Typography mb="20px" typography="openSans.body2">
                                <strong>{`${vendorName}`}</strong>{' '}
                                {`will be removed from the `}
                                <strong
                                    style={{ whiteSpace: 'nowrap' }}
                                >{`${propertyName}`}</strong>
                                {` vendor list. ${
                                    vendorType == 'seller'
                                        ? 'You will no longer be able to purchase their products via RE-OPS.'
                                        : ''
                                }`}
                            </Typography>
                            <Typography
                                sx={{ my: '12px' }}
                                typography="openSans.body2"
                            >
                                {t('They can be added back any time.')}
                            </Typography>
                        </Box>
                    </DialogBoxContent>
                </Dialog>
            )
        );
    },
);
