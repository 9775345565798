import { Box } from '@mui/material';
import { OrdersTableSearch } from './OrdersTableSearch';
import { fetchOrders } from 'entities/Order/model/services/fetchOrders/fetchOrders';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { OrdersTableTabs } from './OrdersTableTabs';
import { useSelector } from 'react-redux';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';
import {
    getOrdersCurrentPage,
    getOrdersPageSize,
    getOrdersTableSorting,
    orderTableIsInitialLoaded,
} from '../model/selectors/catalogItemSelectors';
import { useCallback, useEffect } from 'react';
import { debounce } from 'shared/lib/lodash/lodash';
import { orderTableActions } from '../model/slices/OrderTableSlice';
import { fetchOrdersCounters } from '../model/services/fetchOrdersCounters/fetchOrdersCounters';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
    OrderStatuses,
    getOrderStatusForRoute,
    getOrderStatusFromRoute,
} from 'entities/Order/model/consts/orderStatuses';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { getRouteOrdersTableByStatus } from 'shared/const/router';
import { orderActions } from 'entities/Order/model/slices/OrderSlice';

export const OrdersTable = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const isInitialLoaded = useSelector(orderTableIsInitialLoaded);
    const currentPage = useSelector(getOrdersCurrentPage);
    const pageSize = useSelector(getOrdersPageSize);
    const sorting = useSelector(getOrdersTableSorting);

    const defaultOrderStatus = OrderStatuses.DRAFT;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchTableData = useCallback(
        debounce(async (params, propertyId) => {
            await dispatch(fetchOrders(params));
            await dispatch(fetchOrdersCounters(propertyId));
            if (!isInitialLoaded) {
                dispatch(orderTableActions.setInitialLoaded(true));
            }
        }, 500),
        [],
    );

    useInitialEffect(() => {
        let orderStatus = defaultOrderStatus;

        if (params?.orderStatus) {
            orderStatus = getOrderStatusFromRoute(params.orderStatus);
        }

        navigate(
            getRouteOrdersTableByStatus(
                params.propertyId,
                getOrderStatusForRoute(orderStatus),
            ),
        );
        dispatch(orderActions.setSearchParamsStatus(orderStatus));
    });

    const fetchTableData = useCallback(async () => {
        const orderStatusParamFromRoute = getOrderStatusFromRoute(
            params?.orderStatus,
        );

        const dataParams = {
            propertyId: selectedPropertyId,
            status: orderStatusParamFromRoute,
            pagination: {
                currentPage,
                pageSize,
            },
            sorting,
        };

        if (selectedPropertyId) {
            await debouncedFetchTableData(dataParams, selectedPropertyId);
        }
    }, [
        params?.orderStatus,
        selectedPropertyId,
        currentPage,
        pageSize,
        sorting,
        debouncedFetchTableData,
    ]);

    useEffect(
        () => () => {
            dispatch(orderTableActions.setInitialLoaded(false));
        },
        [dispatch],
    );

    useEffect(() => {
        fetchTableData();
    }, [fetchTableData]);

    return (
        <Box>
            <Box mb="16px" style={{ display: 'none' }}>
                <OrdersTableSearch />
            </Box>
            <Box mb="16px">
                <OrdersTableTabs />
            </Box>
            <Outlet />
        </Box>
    );
};
