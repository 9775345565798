import { AppBar, Box } from '@mui/material';
import { AccountMenu } from 'features/AccountMenu';
import { headerHeightStyle } from 'shared/const/styles';
import reopsLogo from 'shared/assets/svg/re-ops-logo-filled.svg';
import { Notifications } from 'features/Notifications';

export const Header = () => {
    return (
        <>
            <Box height={headerHeightStyle} />
            <AppBar
                sx={{
                    boxShadow: '0px 1px 12px 0px #0000001F',
                    bgcolor: 'common.white',
                    zIndex: 1201,
                }}
            >
                <Box
                    height={headerHeightStyle}
                    display={'flex'}
                    justifyContent={'space-between'}
                    pr="40px"
                    pl="24px"
                >
                    <img
                        width="120"
                        height="64"
                        src={String(reopsLogo)}
                        alt="Re-ops"
                    />
                    <Box display="flex" alignItems="center" gap="24px">
                        <Notifications />
                        <AccountMenu />
                    </Box>
                </Box>
            </AppBar>
        </>
    );
};
