import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { VendorsResponseSchema } from '../types/VendorsSchema';

export const assignVendorToProperty = createAsyncThunk<
    VendorsResponseSchema,
    { vendorId: string; propertyId: string },
    ThunkConfig<string>
>('vendors/assignToProperty', async ({ vendorId, propertyId }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.post<VendorsResponseSchema>(
            `/v1/properties/${propertyId}/vendors`,
            {
                vendorId,
            },
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
