export enum AppRoutes {
    MAIN = 'main',
    AUTH = 'auth',
    ONBOARD_MOBILE = 'onboard-mobile',
    LOGIN = 'login',
    PROPERTIES = 'properties',

    PROPERTIES_ITEM = 'properties-item',
    PROPERTIES_CATALOG = 'properties-catalog',
    PROPERTIES_CATALOG_ITEMS = 'properties-catalog-items',
    PROPERTIES_CATALOG_ITEM = 'properties-catalog-item',

    PROPERTIES_GLOBAL_CATALOG = 'properties-global-catalog',
    PROPERTIES_GLOBAL_CATALOG_ITEMS = 'properties-global-catalog-items',
    PROPERTIES_GLOBAL_CATALOG_ITEM = 'properties-global-catalog-item',

    PROPERTIES_CATALOG_MANAGEMENT = 'properties-catalog-management',
    PROPERTIES_CATALOG_INVENTORY = 'properties-catalog-inventory',
    PROPERTIES_CATALOG_INVENTORY_ITEM = 'properties-catalog-inventory-item',
    PROPERTIES_CATALOG_INVENTORY_CATEGORIES = 'properties-catalog-inventory-categories',
    PROPERTIES_CATALOG_INVENTORY_CATEGORIES_ITEMS = 'properties-catalog-inventory-categories-items',

    INVENTORY_ADD = 'inventory-add',
    PROPERTIES_INFO = 'properties-info',

    ORDERS = 'orders',
    ORDERS_TABLE = 'orders-table',
    ORDERS_TABLE_STATUS = 'orders-table-status',
    ORDERS_ADD_ITEMS = 'orders-add-items',
    ORDERS_ADD_ITEMS_CATEGORIES = 'orders-add-items-categories',
    ORDERS_ADD_ITEMS_INVENTORY = 'orders-add-items-inventory',
    ORDERS_ADD_ITEMS_INVENTORY_DETAILED = 'orders-add-items-inventory-detailed',
    ORDERS_ADD_ITEMS_CATEGORIES_DETAILED = 'orders-add-items-categories-detailed',
    ORDERS_VIEW = 'orders-view',
    ORDERS_VIEW_ITEM_DETAILED = 'orders-view-item-detailed',

    REPORTS = 'reports',
    REPORTS_BY_CATEGORY = 'reports-by-category',
    SETTINGS = 'settings',
    PRODUCT_SOURCING_PAGE_OPERATIONS = 'product-sourcing-page-operations',
    PRODUCT_SOURCING_PAGE_RENOVATIONS = 'product-sourcing-page-renovations',

    VENDORS = 'vendors',
    VENDORS_SUPPLIER_PAGE = 'vendors-supplier-page',
    ADD_VENDORS_PAGE = 'add-vendors-page',
    PROFILE = 'profile',
    PRIVACY_POLICY = 'privacy-policy',
    TERMS_OF_SERVICE = 'terms-of-service',
    PRIVACY_POLICY_EMBEDED = 'privacy-policy-embeded',
    TERMS_OF_SERVICE_EMBEDED = 'terms-of-service-embeded',
    // error pages
    FORBIDDEN = 'forbidden',
    ACCESS_DENIED = 'access-denied',
    NOT_FOUND_PAGE = 'not-found-page',
    // last
    NOT_FOUND = 'not-found',
}

export const getRouteMain = () => '/';
export const getRouteAuth = () => '/auth';
export const getOnboardMobile = () => '/onboard-mobile';
export const getRouteLogin = () => '/login';
export const getRouteProperties = () => `/properties`;
//TODO: replace route when Home page will be ready
export const getRouteHome = () => '/properties';

export const getRoutePropertiesItem = (propertyId: string) =>
    `/properties/${propertyId}`;

export const getRoutePropertiesCatalog = (propertyId: string) =>
    `/properties/${propertyId}/catalog`;

export const getRouteGlobalCatalog = (propertyId: string) =>
    `/properties/${propertyId}/catalog/global`;

export const getRoutePropertiesCatalogSelected = (
    propertyId: string,
    categoryId: string,
) => `/properties/${propertyId}/catalog/${categoryId}`;

export const getRoutePropertiesGlobalCatalogSelected = (
    propertyId: string,
    categoryId: string,
) => `/properties/${propertyId}/catalog/global/${categoryId}`;

export const getRoutePropertiesCatalogItem = (
    propertyId: string,
    categoryId: string,
    itemId: string,
) => `/properties/${propertyId}/catalog/${categoryId}/item/${itemId}`;

export const getRouteGlobalCatalogItem = (
    propertyId: string,
    categoryId: string,
    itemId: string,
) => `/properties/${propertyId}/catalog/global/${categoryId}/item/${itemId}`;

export const getRoutePropertiesCatalogInventory = (propertyId: string) =>
    `/properties/${propertyId}/catalog/inventory`;

export const getRoutePropertiesCatalogInventoryItem = (
    propertyId: string,
    itemId: string,
) => `/properties/${propertyId}/catalog/inventory/${itemId}`;

export const getRouteOrderCatalogCategoriesItem = (itemId: string) =>
    `/orders/add-items/categories/${itemId}`;

export const getRouteOrderCatalogInventoryItem = (itemId: string) =>
    `/orders/add-items/inventory/${itemId}`;

export const getRoutePropertiesCatalogInventoryCategories = (
    propertyId: string,
) => `/properties/${propertyId}/catalog/inventory/categories`;

export const getRoutePropertiesCatalogInventoryCategoriesSelected = (
    propertyId: string,
    categoryId: string,
) => `/properties/${propertyId}/catalog/inventory/categories/${categoryId}`;

export const getRouteInventoryAdd = (propertyId: string) =>
    `/properties/${propertyId}/catalog/inventory/add`;

export const getRouteOrders = () => '/orders';
export const getRouteOrdersTable = (orderId: string) =>
    `/orders/properties/${orderId}`;

export const getRouteOrdersTableByStatus = (
    orderId: string,
    orderStatus: string,
) => `/orders/properties/${orderId}/status/${orderStatus}`;

export const getRouteOrdersAddItems = () => '/orders/add-items';
export const getRouteOrdersAddItemsCategories = () =>
    '/orders/add-items/categories';
export const getRouteOrdersAddItemsInventory = () =>
    '/orders/add-items/inventory';

export const getRouteOrdersView = (id: string) => `/orders/${id}`;
export const getRouteOrdersViewItemDetailed = (
    orderId: string,
    itemId: string,
) => `/orders/${orderId}/item/${itemId}`;

export const getRouteMainReports = () => `/reports`;
export const getRouteMainVendors = (propertyId: string) =>
    `/vendors/property/${propertyId}`;
export const getRouteAddVendors = (propertyId: string) =>
    `/vendors/property/${propertyId}/add-vendors`;
export const getRouteVendorsSupplierPage = (
    supplierId: string,
    propertyId: string,
) => `/vendors/property/${propertyId}/suppliers/${supplierId}`;
export const getRouteReportsByCategory = () => `/reports/category`;

export const getRouteSettings = () => `/settings`;
export const getRouteProductSourcingOperationsPage = () =>
    `/settings/product-sourcing-operations`;
export const getRouteProductSourcingRenovationsPage = () =>
    `/settings/product-sourcing-renovations`;

export const getRouteProfile = () => `/profile/details`;
export const getRoutePrivacyPolicy = () => `/privacy-policy`;
export const getRouteTermsOfService = () => `/terms-of-service`;
export const getRoutePrivacyPolicyEmbeded = () => `/profile/privacy-policy`;
export const getRouteTermsOfServiceEmbeded = () => `/profile/terms-of-service`;

//error pages
export const getRouteAccessDenied = () => '/access-denied';
export const getRouteForbidden = () => '/forbidden';
export const getRouteNotFound = () => '/not-found';
