import { ChangeEvent } from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Skeleton,
    Typography,
} from '@mui/material';
import { MapsHomeWork } from '@mui/icons-material';
import { Property } from 'entities/Property/model/types/PropertySchema';

interface PropertiesDropDownProps {
    properties: (
        | Property
        | {
              id: string;
              propertyNameLegal: string;
          }
    )[];
    isLoadingProperties: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    selectedPropertyId: string;
}

export const PropertiesDropDown = ({
    properties,
    isLoadingProperties,
    onChange,
    selectedPropertyId,
}: PropertiesDropDownProps) => {
    return !isLoadingProperties ? (
        <FormControl
            data-testid="Properties-dropdown"
            sx={{ minWidth: 350, maxWidth: 400 }}
            size="small"
        >
            {properties ? (
                <Select
                    value={selectedPropertyId}
                    onChange={onChange}
                    sx={{
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        '&:hover': {
                            borderColor: 'rgba(0, 0, 0, 0.87)',
                        },

                        '&.MuiOutlinedInput-root fieldset': {
                            border: 'none',
                            '&:hover': {
                                border: 'none',
                            },
                        },
                    }}
                >
                    {properties.map((property) => (
                        <MenuItem
                            sx={{
                                minWidth: 350,
                                maxWidth: 400,
                                borderBottom:
                                    property.id === 'all'
                                        ? '1px solid #00000030'
                                        : '',
                            }}
                            value={property.id}
                            key={property.id}
                        >
                            <Box
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                display="flex"
                                alignItems="center"
                            >
                                <MapsHomeWork color="primary" />
                                <Typography
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    ml="10px"
                                    typography="openSans.subtitle1"
                                >
                                    {property.propertyNameLegal}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            ) : null}
        </FormControl>
    ) : (
        <Skeleton width="350px" height="43px" variant="rounded" />
    );
};
