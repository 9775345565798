import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderProposalFilterSchema } from '../types/OrderProposalFilterSchema';
import { fetchOrder } from 'entities/Order';
import { deleteOrderItem } from 'features/removeItemsInOrder/model/services/deleteOrderItem/deleteOrderItem';
import { editOrderItem } from 'features/editItemsInOrder/model/services/editOrderItem/editOrderItem';
import { unset } from 'shared/lib/lodash/lodash';

const initialState: OrderProposalFilterSchema = {
    filter: 'All',
    filteredItems: [],
    filterSellersData: {},
};

export const OrderProposalFilterSlice = createSlice({
    name: 'OrderProposalFilter',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
        },
        setFilteredItems: (state, action) => {
            state.filteredItems = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrder.fulfilled, (state, action) => {
                const { data } = action.payload;

                const sellersData: OrderProposalFilterSchema['filterSellersData'] =
                    {};

                data.items.forEach((item) => {
                    const selectedProposal = item.selectedProposal;

                    sellersData[item.id] = {
                        ...selectedProposal,
                        price: Number(selectedProposal.price) * item.qty,
                    };
                });

                state.filterSellersData = sellersData;
            })
            .addCase(deleteOrderItem.fulfilled, (state, action) => {
                const deletedItem = action.payload;

                const foundData = state.filterSellersData?.[deletedItem.id];

                if (foundData) {
                    unset(state.filterSellersData, deletedItem.id);
                }
            })
            .addCase(editOrderItem.fulfilled, (state, action) => {
                const editedItem = action.payload;
                const selectedProposal = editedItem.selectedProposal;

                let foundData = state.filterSellersData?.[editedItem.id];

                if (foundData) {
                    foundData = {
                        ...selectedProposal,
                        price: Number(selectedProposal.price) * editedItem.qty,
                    };

                    state.filterSellersData = {
                        ...state.filterSellersData,
                        [editedItem.id]: foundData,
                    };
                }
            });
    },
});

export const { actions: orderProposalFilterActions } = OrderProposalFilterSlice;
export const { reducer: orderProposalFilterReducer } = OrderProposalFilterSlice;
