import { useTranslation } from 'react-i18next';
import { memo, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { addCatalogItemActions } from 'features/AddCatalogItem/model/slices/AddCatalogItemSlice';
import { useMatch, useNavigate } from 'react-router-dom';
import {
    getRouteGlobalCatalog,
    getRoutePropertiesCatalogInventory,
    getRoutePropertiesCatalogInventoryCategories,
    getRoutePropertiesCatalogInventoryItem,
} from 'shared/const/router';
import { addInventoryItemActions } from 'features/AddInventoryItem/model/slices/AddInventoryItemSlice';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { useSelector } from 'react-redux';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';
import { categoryActions } from 'entities/Category/model/slices/CategorySlice';

export const AddCatalogItemStartButton = memo(() => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const selectedPropertyId = useSelector(getSelectedPropertyId);

    const currentPageIsInventory = useMatch(
        getRoutePropertiesCatalogInventory(':propertyId'),
    );
    const currentPageIsInventoryItem = useMatch(
        getRoutePropertiesCatalogInventoryItem(':propertyId', ':itemId'),
    );

    const onAddNewItem = useCallback(() => {
        if (currentPageIsInventory || currentPageIsInventoryItem) {
            dispatch(addInventoryItemActions.setEditItemMode(true));
            navigate(
                getRoutePropertiesCatalogInventoryCategories(
                    selectedPropertyId,
                ),
            );
        } else {
            dispatch(addCatalogItemActions.setEditItemMode(true));
            navigate(getRouteGlobalCatalog(selectedPropertyId));
        }

        dispatch(catalogItemActions.closeDetailedItem());
        dispatch(categoryActions.clearCategorySelection());
    }, [
        currentPageIsInventory,
        currentPageIsInventoryItem,
        dispatch,
        navigate,
        selectedPropertyId,
    ]);

    return (
        <Button
            data-testid="Add-new-item-button"
            size="small"
            variant="contained"
            onClick={onAddNewItem}
            startIcon={<Add />}
        >
            <Typography typography="openSans.button">
                {t('Add new item')}
            </Typography>
        </Button>
    );
});
