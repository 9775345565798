import { Chip, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { ChangeEventHandler, ReactNode, useEffect } from 'react';

type SearchFieldProps = {
    setValue: (value: string) => void;
    value: string;
    chipLabel?: ReactNode;
    onChipDelete?: () => void;
    hideBorder?: boolean;
    resettable?: boolean;
    isActive?: boolean;
} & TextFieldProps;

export const SearchField = ({
    value,
    setValue,
    onChipDelete,
    hideBorder,
    chipLabel,
    resettable = false,
    isActive = false,
    ...props
}: SearchFieldProps) => {
    const showClearIcon = value?.length === 0;

    useEffect(() => {
        if (!isActive && resettable) {
            setValue('');
        }
    }, [isActive, setValue, resettable]);

    const onReset = () => {
        setValue('');
    };

    const onDeleteChip = () => {
        if (onChipDelete) {
            onChipDelete();
        }
    };

    const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setValue(e.target.value);
    };

    return (
        <>
            <input style={{ display: 'none' }} type="text" autoComplete="off" />

            <TextField
                data-testid="Search-bar"
                {...props}
                fullWidth
                size="small"
                value={value}
                onChange={onChange}
                variant="outlined"
                sx={
                    hideBorder
                        ? {
                              '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                              },
                          }
                        : {}
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            sx={{ height: '48px', mr: 0 }}
                        >
                            <Search sx={{ width: '24px', height: '24px' }} />
                            {chipLabel && (
                                <Chip
                                    label={chipLabel}
                                    onDelete={onDeleteChip}
                                    sx={{ m: '0 4px' }}
                                />
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            style={{
                                display: showClearIcon && 'none',
                                cursor: 'pointer',
                            }}
                            onClick={onReset}
                        >
                            <Clear />
                        </InputAdornment>
                    ),
                    sx: { height: '48px' },
                }}
            />
        </>
    );
};
