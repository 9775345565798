import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
    getSelectedOrderId,
    getSelectedOrderType,
} from 'entities/Order/model/selectors/ordersSelectors';

import {
    getPropertyCatalogId,
    getPropertyId,
} from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRouteOrdersAddItemsCategories } from 'shared/const/router';

export const AddItemsToOrderButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const selectedOrderId = useSelector(getSelectedOrderId);
    const selectedOrderType = useSelector(getSelectedOrderType);
    const selectedPropertyCatalogId = useSelector(getPropertyCatalogId);
    const selectedPropertyId = useSelector(getPropertyId);

    const navigateToCatalog = () => {
        navigate(getRouteOrdersAddItemsCategories(), {
            state: {
                selectedOrderId,
                selectedPropertyCatalogId,
                selectedPropertyId,
                fromOrder: selectedOrderId,
                orderType: selectedOrderType,
            },
        });
    };

    return (
        <Button
            style={{ margin: '10px' }}
            variant="outlined"
            onClick={navigateToCatalog}
            startIcon={<Add />}
            data-testid="btn-add-item"
        >
            {t('Add item')}
        </Button>
    );
};
