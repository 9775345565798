import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Skeleton, Box } from '@mui/material';
import { SearchField } from 'shared/ui/SearchField/SearchField';
import { fetchPropertyVendorsList, Vendor } from 'entities/Vendors';
import { getIsLoadingVendorsList } from 'entities/Vendors/model/selectors/vendorsSelectors';
import { getRouteVendorsSupplierPage } from 'shared/const/router';
import {
    getSelectedProperty,
    getSelectedPropertyId,
} from 'entities/Property/model/selectors/PropertySelectors';
import { VendorsTable } from 'shared/ui/VendorsTable/VendorsTable';
import RemoveVendorModal from 'shared/ui/RemoveVendorModal/RemoveVendorModal';
import { deleteVendorFromProperty } from 'entities/Vendors/model/services/deleteVendorFromProperty';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { getUserRole, UserRole } from 'entities/User';

type Indexable = {
    [key: string]: string | Array<string> | boolean;
};

export const PropertyVendorsTable = ({
    vendorsList,
}: {
    vendorsList: Vendor[];
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoadingVendors = useSelector(getIsLoadingVendorsList);
    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const selectedPropertyData = useSelector(getSelectedProperty);
    const [isShowDeleteVendorModal, setIsShowDeleteVendorModal] =
        useState(false);
    const [isLoadingVendor, setIsLoadingVendor] = useState(false);
    const [deleteVendor, setDeleteVendor] = useState<null | Vendor>(null);
    const [search, setSearch] = useState('');

    const currentUserRole = useSelector(getUserRole);

    const canManageVendors = [
        UserRole.PMC_ADMIN,
        UserRole.PROPERTY_MANAGER,
    ].includes(currentUserRole);

    const goToSupplierPage = useCallback(
        (supplierId: string) => {
            navigate(
                getRouteVendorsSupplierPage(supplierId, selectedPropertyId),
            );
        },
        [navigate, selectedPropertyId],
    );

    const onOpenDeleteVendor = useCallback(
        (vendor: Vendor) => {
            setDeleteVendor(vendor);
            setIsShowDeleteVendorModal(true);
        },
        [setDeleteVendor, setIsShowDeleteVendorModal],
    );

    const onCloseDeleteVendor = useCallback(() => {
        setIsShowDeleteVendorModal(false);
        setDeleteVendor(null);
    }, []);

    const onDeleteVendor = useCallback(
        async (id: string) => {
            setIsLoadingVendor(true);
            await dispatch(
                deleteVendorFromProperty({
                    propertyId: selectedPropertyData?.id,
                    vendorId: id,
                }),
            );
            onCloseDeleteVendor();
            setIsLoadingVendor(false);
            await dispatch(fetchPropertyVendorsList(selectedPropertyData?.id));
        },
        [dispatch, selectedPropertyData, onCloseDeleteVendor],
    );

    const searchable = [
        'title',
        'email',
        'primaryContact',
        'phone',
        'speciality',
    ];

    const filteredVendors = vendorsList?.filter((vendor: Vendor) => {
        const index: Indexable = Object.keys(vendor)
            .filter((key) => searchable.includes(key))
            .reduce((values: Indexable, key: string) => {
                values[key] = vendor[key as keyof Vendor];
                return values;
            }, {});

        return JSON.stringify(Object.values(index))
            .toLowerCase()
            .includes(search.toLowerCase());
    });

    return (
        <Box sx={{ mt: '20px' }}>
            <SearchField
                value={search}
                setValue={setSearch}
                placeholder={t('Search vendor')}
            />
            <VendorsTable
                vendorsList={filteredVendors}
                isLoadingVendors={isLoadingVendors}
                isMainVendorsPage
                onClickRow={goToSupplierPage}
                onDeleteVendor={canManageVendors ? onOpenDeleteVendor : null}
                deleteVendorId={deleteVendor?.id}
            />
            {isLoadingVendors &&
                Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="rectangular"
                        height="50px"
                        sx={{
                            marginBottom: '2px',
                            width: '100%',
                        }}
                    />
                ))}
            <RemoveVendorModal
                isShowDeleteVendor={isShowDeleteVendorModal}
                title={
                    deleteVendor?.sellerType === 'seller'
                        ? 'Remove Supplier'
                        : 'Remove Service Provider'
                }
                buttonText={
                    deleteVendor?.sellerType === 'seller'
                        ? 'Remove supplier'
                        : 'Remove service provider'
                }
                vendorType={deleteVendor?.sellerType}
                vendorName={deleteVendor?.title}
                propertyName={selectedPropertyData?.propertyNameLegal}
                onCloseDeleteVendor={onCloseDeleteVendor}
                onSubmitDeleteVendor={() => onDeleteVendor(deleteVendor?.id)}
                isLoadingDeleteVendor={isLoadingVendor}
            />
        </Box>
    );
};
