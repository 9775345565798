import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, NotificationSchema } from '../types/NotificationsSchema';
import { fetchNotifications } from '../services/fetchNotifications/fetchNotifications';
import { notificationPageSize } from '../conts/notificationsConst';
import { fetchMoreNotifications } from '../services/fetchMoreNotifications/fetchMoreNotifications';

const initialState: NotificationSchema = {
    initialIsLoading: false,
    moreIsLoading: false,
    unreadCounter: 0,
    unreadIds: [],
    pagination: {
        pageSize: notificationPageSize,
        currentPage: null,
        hasMore: false,
    },
};

export const NotificationsSlice = createSlice({
    name: 'Notification',
    initialState,
    reducers: {
        makeAllRead: (state) => {
            state.dataList = state.dataList.map((item) => ({
                ...item,
                isRead: true,
            }));

            state.unreadCounter = 0;
        },
        makeReadByIndex: (state, action: PayloadAction<number>) => {
            const updatedList = [...state.dataList];
            updatedList[action.payload] = {
                ...updatedList[action.payload],
                isRead: true,
            };

            state.unreadCounter =
                state.unreadCounter > 1
                    ? state.unreadCounter - 1
                    : state.unreadCounter;
            state.dataList = updatedList;
        },
        updatedNotification: (state, action: PayloadAction<Notification>) => {
            const newDataList = [...state.dataList];
            newDataList.unshift(action.payload);

            if (
                newDataList.length >
                state.pagination.pageSize * state.pagination.currentPage
            ) {
                newDataList.pop();
                state.pagination.hasMore = true;
            }

            state.unreadCounter = state.unreadCounter + 1;
            state.dataList = newDataList;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.dataList = null;
                state.initialIsLoading = true;
                state.unreadIds = [];
                state.error = null;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                const data = action.payload;
                const notifications = data.results;
                state.dataList = notifications;

                state.pagination.hasMore = Boolean(data.next);

                let notificationsUnreadIds: string[] = [];

                notifications.forEach((notification) => {
                    if (!notification.isRead) {
                        notificationsUnreadIds.push(notification.id);
                    }
                });

                state.pagination.currentPage = 1;
                state.unreadIds = notificationsUnreadIds;
                state.unreadCounter = data.unreadCount;
                state.initialIsLoading = false;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.dataList = null;
                state.initialIsLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchMoreNotifications.pending, (state) => {
                state.moreIsLoading = true;
                state.error = null;
            })
            .addCase(fetchMoreNotifications.fulfilled, (state, action) => {
                const data = action.payload;
                const notifications = data.results;
                state.dataList = [...state.dataList, ...notifications];

                state.pagination.hasMore = Boolean(data.next);

                let notificationsUnreadIds: string[] = [...state.unreadIds];

                notifications.forEach((notification) => {
                    if (!notification.isRead) {
                        notificationsUnreadIds.push(notification.id);
                    }
                });

                state.unreadIds = notificationsUnreadIds;
                state.pagination.currentPage = state.pagination.currentPage + 1;
                state.moreIsLoading = false;
            })
            .addCase(fetchMoreNotifications.rejected, (state, action) => {
                state.moreIsLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: NotificationActions } = NotificationsSlice;
export const { reducer: NotificationReducer } = NotificationsSlice;
