import { memo, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Loader } from 'shared/ui/Loader/Loader';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { createOrder } from 'entities/Order/model/services/createOrder/createOrder';
import { getSelectedCatalogItems } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isEmpty, isObject } from 'shared/lib/lodash/lodash';
import { addItemsToOrderService } from 'features/AddItemsToOrder/model/services/addItemsToOrderService';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRouteOrdersView } from 'shared/const/router';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';

interface CreateOrderWithItemsButtonProps {
    isLoadingUpdateOrder?: boolean;
    orderType?: string;
}

export const CreateOrderWithItemsButton = memo(
    ({ isLoadingUpdateOrder, orderType }: CreateOrderWithItemsButtonProps) => {
        const { t } = useTranslation();
        const location = useLocation();
        const dispatch = useAppDispatch();
        const navigate = useNavigate();

        const [isLoading, setLoading] = useState(false);
        const selectedItems = useSelector(getSelectedCatalogItems);

        const selectedPropertyId = useSelector(getSelectedPropertyId);

        const selectedOrderId = location.state?.selectedOrderId;
        const propertyId =
            location.state?.selectedPropertyId || selectedPropertyId;
        const isDisabled =
            isLoading || isEmpty(selectedItems) || isLoadingUpdateOrder;

        const createOrderByPropId = useCallback(
            async (propertyId: string) => {
                const sendOrderType =
                    orderType === 'Renovations' ? 'RENOVATION' : 'OPERATION';
                const response = await dispatch(
                    createOrder({ propertyId, orderType: sendOrderType }),
                );

                if (response.meta.requestStatus === 'rejected') {
                    setLoading(false);
                    toast(t('Order was not created'));
                    return;
                }

                const order = response.payload;

                return isObject(order) && 'id' in order ? order.id : undefined;
            },
            [orderType, dispatch, t],
        );

        const addItemsToOrderById = useCallback(
            async (orderId: string) => {
                const data = {
                    items: Object.entries(selectedItems).map(
                        ([key, value]) => ({
                            catalogItemId: key,
                            qty: value.qty,
                        }),
                    ),
                };

                const response = await dispatch(
                    addItemsToOrderService({ data, orderId }),
                );

                if (response.meta.requestStatus !== 'rejected') {
                    toast(t('Order created'));
                } else {
                    toast(t('Order creation failed'));
                }

                dispatch(catalogItemActions.removeAllSelection());
            },
            [dispatch, selectedItems, t],
        );

        const onSubmit = useCallback(async () => {
            setLoading(true);
            const orderId =
                selectedOrderId || (await createOrderByPropId(propertyId));

            if (orderId && !selectedOrderId) {
                await addItemsToOrderById(orderId);
            }
            navigate(getRouteOrdersView(orderId), {
                state: { selectedOrderId: orderId },
            });

            setLoading(false);
        }, [
            addItemsToOrderById,
            createOrderByPropId,
            navigate,
            propertyId,
            selectedOrderId,
        ]);

        return (
            <Button
                startIcon={isLoading && <Loader color="inherit" />}
                disabled={isDisabled}
                onClick={onSubmit}
                variant="contained"
                data-testid="btn-review-order"
            >
                {t('Review order')}
            </Button>
        );
    },
);
