import React, { CSSProperties } from 'react';
import { Box } from '@mui/material';

type ImageProps = {
    src?: string | null;
    placeholder?: React.FC<React.SVGProps<SVGSVGElement>>;
    style?: CSSProperties;
    imageStyle?: CSSProperties;
    title?: string;
};

export const Image = ({
    src,
    placeholder,
    style,
    imageStyle,
    title,
}: ImageProps) => {
    const imageCss = {
        ...imageStyle,
        height: '100%',
        width: '100%',
        display: 'block',
    } as CSSProperties;
    const containerCss = {
        ...style,
        backgroundImage: `url(${placeholder})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    };

    return (
        <Box style={containerCss}>
            {src && (
                <img
                    alt={title}
                    data-testid="image"
                    src={src}
                    title={title}
                    style={imageCss}
                    onLoad={(event) => {
                        let parent = (event.target as HTMLElement)
                            .parentNode as HTMLElement;
                        parent.style.backgroundImage = 'none';
                    }}
                    // @ts-ignore
                    onError={(event) => (event.target.style.display = 'none')}
                />
            )}
        </Box>
    );
};
