import { Typography, alpha, AccordionSummary } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

export const AccordionHeader = ({ title }: { title: string }) => {
    return (
        <AccordionSummary
            sx={{ m: '8px 0' }}
            expandIcon={
                <ArrowDropDown
                    sx={(theme) => ({
                        color: alpha(theme.palette.common?.black, 0.3),
                    })}
                />
            }
        >
            <Typography typography="openSans.subtitle1Medium">
                {title}
            </Typography>
        </AccordionSummary>
    );
};
