import { memo } from 'react';
import { Box, Typography, BoxProps } from '@mui/material';
import { PropertyCardRow } from './PropertyCardRow';
import { useTranslation } from 'react-i18next';
import { PropertyContact } from 'entities/Property/model/types/PropertySchema';

type PropertyCardShortInfoBlockProps = {
    title: string;
    data?: PropertyContact;
} & BoxProps;

export const PropertyCardShortInfoBlock = memo(
    ({ data, title, mb }: PropertyCardShortInfoBlockProps) => {
        const { t } = useTranslation();

        const email = data?.contactInfo
            ? data?.contactInfo.match(
                  '[a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+',
              )?.[0] || ''
            : '';

        return (
            <Box
                bgcolor="background.paper"
                border="1px solid"
                borderColor="grey.500"
                borderRadius="4px"
                p="16px 16px 10px"
                mb={mb}
            >
                <Typography typography={'openSans.body2'} mb="10px">
                    {title}
                </Typography>

                <PropertyCardRow
                    name={t('Name')}
                    value={data?.name || '-'}
                    minimized
                />
                <PropertyCardRow
                    name={t('Title')}
                    value={data?.role || '-'}
                    minimized
                />
                <PropertyCardRow name={t('Email')} value={email} minimized />
            </Box>
        );
    },
);
