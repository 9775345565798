import {
    Proposal,
    SelectedProposal,
} from 'entities/Proposal/model/types/ProposalSchema';

export const getDataForSelectedProposal = (
    proposal: Proposal,
): SelectedProposal => {
    if (!proposal) return null;

    return {
        name: proposal.seller.name,
        price: proposal.prices?.total || null,
        uid: proposal.uid,
    };
};
