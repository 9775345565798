import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
    PatchCatalogItemsRequest,
    PatchCatalogItemsResponse,
} from 'entities/CatalogItem/model/types/CatalogItemSchema';

import { reorderInventoryItemActions } from '../slices/ReorderInventoryItemSlice';

export const reorderInventoryItemService = createAsyncThunk<
    PatchCatalogItemsResponse,
    PatchCatalogItemsRequest,
    ThunkConfig<any>
>('catalogItem/reorderFromInventory', async ({ data, catalogId }, thunkApi) => {
    const { extra, rejectWithValue, dispatch } = thunkApi;

    try {
        const response = await extra.api.patch<PatchCatalogItemsResponse>(
            `/v1/catalogs/${catalogId}/items`,
            data,
        );

        dispatch(reorderInventoryItemActions.setEditFlow(false));
        dispatch(reorderInventoryItemActions.setEditFlowFromCategories(false));

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
