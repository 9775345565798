import { ChangeEvent } from 'react';
import {
    getPropertiesList,
    getPropertiesListLoading,
    getSelectedPropertyId,
} from 'entities/Property/model/selectors/PropertySelectors';
import { propertyActions } from 'entities/Property/model/slices/PropertySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteOrdersTableByStatus } from 'shared/const/router';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import {
    OrderStatuses,
    getOrderStatusForRoute,
} from 'entities/Order/model/consts/orderStatuses';
import { orderActions } from 'entities/Order/model/slices/OrderSlice';
import { PropertiesDropDown } from 'widgets/PropertiesDropDown';

export const PropertySelector = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const properties = useSelector(getPropertiesList);
    const propertyIsLoading = useSelector(getPropertiesListLoading);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const propertyId = event.target.value;
        dispatch(propertyActions.selectProperty(propertyId));
        dispatch(orderActions.setSearchParamsStatus(OrderStatuses.DRAFT));
        navigate(
            getRouteOrdersTableByStatus(
                propertyId,
                getOrderStatusForRoute(OrderStatuses.DRAFT),
            ),
        );
    };

    useInitialEffect(() => {
        const propertyIdParam = params?.propertyId;
        if (propertyIdParam) {
            dispatch(propertyActions.selectProperty(propertyIdParam));
        }
    });

    return (
        <PropertiesDropDown
            properties={properties}
            isLoadingProperties={propertyIsLoading}
            onChange={onChange}
            selectedPropertyId={selectedPropertyId}
        />
    );
};
