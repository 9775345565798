import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, alpha } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Page } from 'widgets/Page';
import { NeedAnyAssistance } from 'shared/ui/NeedAnyAssistance/NeedAnyAssistance';
import { innerPageHeight } from 'shared/const/styles';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { userActions } from 'entities/User';
import { useNavigate } from 'react-router-dom';
import { getRouteMain } from 'shared/const/router';

export const AccessDeniedPage = memo(() => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onLogout = useCallback(async () => {
        dispatch(userActions.logout());
        navigate(getRouteMain());
    }, [dispatch, navigate]);

    return (
        <Page dataTestId="AccessDeniedPage">
            <Box
                display="flex"
                justifyContent="center"
                height={innerPageHeight}
                sx={{ overflowY: 'scroll' }}
            >
                <Box
                    width="100%"
                    maxWidth="400px"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Typography typography="poppins.h5" mt="40px" mb="42px">
                        {t('Access Denied')}
                    </Typography>

                    <Box mb="52px" color={alpha('#9FB6DB', 0.85)}>
                        <LockOutlinedIcon
                            sx={{ width: '100px', height: '100px' }}
                        />
                    </Box>

                    <Typography
                        mb="16px"
                        typography="openSans.h6"
                        textAlign="center"
                    >
                        {t('Sorry, you don’t have permissions to access')}
                    </Typography>

                    <Typography
                        mb="45px"
                        typography="openSans.body1"
                        textAlign="center"
                    >
                        {t(
                            'Please try sign in again using the proper credentials or use RE-OPS Marketplace App',
                        )}
                        .
                    </Typography>

                    <Box mb="120px" width="100%">
                        <Button
                            onClick={onLogout}
                            fullWidth
                            data-testid="AccessDeniedPage.Button"
                            variant="contained"
                        >
                            {t('Go to sign-in')}
                        </Button>
                    </Box>

                    <NeedAnyAssistance />
                </Box>
            </Box>
        </Page>
    );
});
