import { useTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    Box,
    Typography,
    alpha,
} from '@mui/material';
import { AccordionHeader } from 'shared/ui/AccordionHeader/AccordionHeader';
import { InvoiceSchema } from 'entities/Order/model/types/OrderSchema';
import { getPrice } from 'shared/lib/getPrice/getPrice';

export const OrderInvoiceDetails = ({
    invoiceStatus,
}: {
    invoiceStatus: InvoiceSchema[];
}) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ my: '16px' }}>
            <Accordion
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.grey[500], 0.5),
                    borderRadius: '12px',
                })}
                defaultExpanded
            >
                <AccordionHeader title={t('Invoice details')} />
                <AccordionDetails sx={{ p: '0 16px 12px' }}>
                    {invoiceStatus.map((invoice, index) => (
                        <Box key={index} sx={{ mb: '12px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    mb: '8px',
                                }}
                            >
                                {invoice.seller || 'Ferguson'}
                            </Typography>
                            <Box
                                sx={{ paddingLeft: '30px', marginTop: '12px' }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '15px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {`Invoice: ${invoice.biId}`}
                                </Typography>
                                <Box
                                    sx={{
                                        paddingLeft: '12px',
                                        marginTop: '8px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: '8px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '15px',
                                                fontWeight: 400,
                                            }}
                                        >
                                            {t('Total')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '15px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {getPrice(invoice.prices?.total)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
