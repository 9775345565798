import { Box, Typography } from '@mui/material';
import { Proposal } from 'entities/Proposal/model/types/ProposalSchema';
import { useFeatureFlag } from 'shared/lib/hooks/useFeatureFlag';
import { ColoredLinkBox } from 'shared/ui/ColoredLinkBox';

interface SuppliersListProps {
    proposals: Proposal[];
    activatedAccounts: { [key: string]: boolean[] };
}

export const SuppliersList = ({
    proposals,
    activatedAccounts,
}: SuppliersListProps) => {
    const renovationEnabled = useFeatureFlag('renovation_enabled');
    return (
        <>
            {proposals?.map((proposal) => {
                const statuses = activatedAccounts[proposal.seller.uid] || [
                    false,
                    false,
                ];

                const [isOperationsActivated, isRenovationsActivated] =
                    statuses;

                return (
                    <Box
                        key={proposal.uid}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <Typography
                            sx={{
                                marginY: '10px',
                                color: '#1D617A',
                            }}
                            typography="openSans.subtitle1Medium"
                        >
                            {proposal.seller.name}
                        </Typography>

                        {isOperationsActivated && (
                            <ColoredLinkBox
                                color="#DBEFE1"
                                tooltipText={
                                    renovationEnabled
                                        ? 'Operations account activated'
                                        : 'Account activated'
                                }
                            />
                        )}

                        {isRenovationsActivated && renovationEnabled && (
                            <ColoredLinkBox
                                color="#5CA1FF66"
                                tooltipText="Renovations account activated"
                            />
                        )}
                    </Box>
                );
            })}
        </>
    );
};
