import { Box, Button, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRouteHome } from 'shared/const/router';
import { innerPageHeight } from 'shared/const/styles';
import { Page } from 'widgets/Page';
import forbiddenIcon from 'shared/assets/svg/403.svg';
import { NeedAnyAssistance } from 'shared/ui/NeedAnyAssistance/NeedAnyAssistance';

const ForbiddenPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClick = useCallback(async () => {
        navigate(getRouteHome());
    }, [navigate]);

    return (
        <Page dataTestId="ForbiddenPage">
            <Box
                display="flex"
                justifyContent="center"
                height={innerPageHeight}
                sx={{ overflowY: 'scroll' }}
            >
                <Box
                    width="100%"
                    maxWidth="400px"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Typography typography="poppins.h5" mt="40px" mb="42px">
                        {t('Access Denied')}
                    </Typography>

                    <Box mb="74px">
                        <img src={String(forbiddenIcon)} alt={t('Not found')} />
                    </Box>

                    <Typography
                        mb="16px"
                        typography="openSans.h6"
                        textAlign="center"
                    >
                        {t('Sorry, you don’t have permissions to access')}
                    </Typography>

                    <Typography
                        mb="76px"
                        typography="openSans.body1"
                        textAlign="center"
                    >
                        {t('Please go back to Home page')}.
                    </Typography>

                    <Box mb="120px" width="100%">
                        <Button
                            onClick={onClick}
                            fullWidth
                            variant="contained"
                            data-testid="ForbiddenPage.Button"
                        >
                            {t('Go to home page')}
                        </Button>
                    </Box>

                    <NeedAnyAssistance />
                </Box>
            </Box>
        </Page>
    );
};

export default ForbiddenPage;
