import { MouseEventHandler, memo, useCallback } from 'react';
import { Box, Button, Typography, alpha } from '@mui/material';
import { convertDate, isToday } from 'shared/lib/convertDate/convertDate';
import { getRouteOrdersView } from 'shared/const/router';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { makeNotificationsAsReadService } from 'features/MakeNotificationsRead';
import {
    OrderStatuses,
    useGetOrderStatusTitle,
} from 'entities/Order/model/consts/orderStatuses';
import { useTranslation } from 'react-i18next';

interface NotificationsProps {
    id: string;
    createdAt: string;
    objectId: string;
    isRead: boolean;
    propertyLegalName: string;
    orderNumber: string;
    status: OrderStatuses;
    triggeredBy: string;
    showBorder: boolean;
    closePopup: () => void;
    markReadByIndex: () => void;
}

export const Notification = memo(
    ({
        id,
        createdAt,
        objectId,
        isRead,
        propertyLegalName,
        orderNumber,
        status,
        triggeredBy,
        showBorder,
        closePopup,
        markReadByIndex,
    }: NotificationsProps) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const dispatch = useAppDispatch();

        const translatedStatus = useGetOrderStatusTitle(status);

        const title = `Order ${orderNumber || ''} - ${translatedStatus || ''}`;
        const updatedBy = `Status updated by ${triggeredBy || ''}`;

        const isCurrentDay = isToday(createdAt);
        const { day, time } = convertDate(createdAt);

        const displayDay = `${isCurrentDay ? '' : day} ${time}`;

        const markRead = useCallback(async () => {
            markReadByIndex();
            await dispatch(makeNotificationsAsReadService([id]));
        }, [dispatch, id, markReadByIndex]);

        const onClick = useCallback(async () => {
            await markRead();
            closePopup();

            navigate(getRouteOrdersView(objectId));
        }, [markRead, closePopup, navigate, objectId]);

        const onMarkReadClick: MouseEventHandler<HTMLButtonElement> =
            useCallback(
                (e) => {
                    e.stopPropagation();
                    markRead();
                },
                [markRead],
            );

        return (
            <Box
                display="flex"
                onClick={onClick}
                p="16px"
                data-testid="Notification"
                sx={{ cursor: 'pointer' }}
                borderBottom="1px solid"
                borderColor={showBorder ? 'divider' : 'transparent'}
            >
                <Box
                    data-testid="Notification.ActiveChip"
                    style={{ opacity: !isRead ? 1 : 0 }}
                    width="10px"
                    height="10px"
                    borderRadius="50%"
                    bgcolor="primary.500"
                    mr="8px"
                    mt="4px"
                />

                <Box flex="1">
                    <Typography mb="8px" typography="openSans.subtitle1Medium">
                        {title}
                    </Typography>
                    <Typography
                        mb="8px"
                        typography="openSans.body2Medium"
                        color={(theme) =>
                            alpha(theme.palette.common.black, 0.85)
                        }
                    >
                        {updatedBy}
                    </Typography>
                    {!isRead && (
                        <Typography
                            mb="4px"
                            typography="openSans.body2"
                            color={(theme) =>
                                alpha(theme.palette.common.black, 0.5)
                            }
                        >
                            {propertyLegalName}
                        </Typography>
                    )}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {isRead ? (
                            <Typography
                                mb="4px"
                                typography="openSans.body2"
                                color={(theme) =>
                                    alpha(theme.palette.common.black, 0.5)
                                }
                            >
                                {propertyLegalName}
                            </Typography>
                        ) : (
                            <Button
                                size="small"
                                sx={{ ml: '-10px' }}
                                onClick={onMarkReadClick}
                            >
                                <Typography typography="openSans.subtitle2">
                                    {t('Mark as read')}
                                </Typography>
                            </Button>
                        )}
                        <Typography
                            height="20px"
                            typography="openSans.body2"
                            color={(theme) =>
                                alpha(theme.palette.common.black, 0.5)
                            }
                        >
                            {displayDay}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    },
);
