import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { VendorsResponseSchema } from '../types/VendorsSchema';

export const fetchPropertyVendorsList = createAsyncThunk<
    VendorsResponseSchema,
    string,
    ThunkConfig<string>
>('vendors/listVendors', async (propertyId, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<VendorsResponseSchema>(
            `/v1/properties/${propertyId}/vendors`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
