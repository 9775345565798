import { Typography, Fade } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const CustomWidthTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ),
)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 684,
        padding: '6px 16px',
        backgroundColor: '#ffffff',
        color: '#000000',
        margin: '16px',
        borderRadius: '8px',
        boxShadow:
            '0px 5px 6px -3px #00000033, 0px 9px 12px 1px #00000024,0px 3px 16px 2px #0000001F ',
        '& .MuiTooltip-arrow': {
            color: '#ffffff',
        },
    },
});

export const TooltipInfo = ({
    content,
    isSecondaryColor,
}: {
    content: string | JSX.Element;
    isSecondaryColor?: boolean;
}) => {
    const renderContent = () => {
        if (typeof content === 'object' && content.type === 'img') {
            return (
                <img
                    src={content.props.src}
                    alt={content.props.alt}
                    style={{
                        display: 'block',
                        width: content.props.width,
                        height: content.props.height,
                        margin: '-6px -16px',
                    }}
                />
            );
        } else {
            return (
                <div style={{ width: 300 }}>
                    <Typography
                        sx={{ whiteSpace: 'pre-wrap' }}
                        typography="openSans.caption"
                    >
                        {content}
                    </Typography>
                </div>
            );
        }
    };

    return (
        <CustomWidthTooltip
            arrow
            placement="right"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 200 }}
            title={renderContent()}
        >
            <InfoOutlinedIcon
                sx={{ color: isSecondaryColor ? 'grey.400' : 'primary' }}
            />
        </CustomWidthTooltip>
    );
};
