import { MouseEventHandler, memo, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Radio,
    Typography,
    alpha,
    Button,
    TextField,
    Skeleton,
} from '@mui/material';
import {
    CheckOutlined,
    Clear,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { orderProposalFilterActions } from 'features/OrderProposalFilter/model/slices/OrderProposalFilterSlice';
import {
    getProposalFilter,
    getProposalFilterSellersData,
} from 'features/OrderProposalFilter/model/selectors/orderProposalFilteSelectors';
import { getCatalogItems } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { useUnmountEffect } from 'shared/lib/hooks/useUnmountEffect';
import { SelectedProposal } from 'entities/Proposal/model/types/ProposalSchema';
import {
    getSelectedOrder,
    getSelectedPoNumberIsLoading,
} from 'entities/Order/model/selectors/ordersSelectors';
import { editPurchaseOrderNumber } from '../../model/services/editPurchaseOrderNumber';
import { UserRole, getUserRole } from 'entities/User';
import { getSelectedProperty } from 'entities/Property/model/selectors/PropertySelectors';
import { getPrice } from 'shared/lib/getPrice/getPrice';

export const OrderProposalFilter = memo(() => {
    const { t } = useTranslation();
    const [editPoProposalId, setEditPoProposalId] = useState('');
    const [tempPoValue, setTempPoValue] = useState('');
    const dispatch = useAppDispatch();

    const currentUserRole = useSelector(getUserRole);
    const isSupervisor = currentUserRole === UserRole.MAINTENANCE_SUPERVISOR;

    const currentProperty = useSelector(getSelectedProperty);
    const currentOrder = useSelector(getSelectedOrder);
    const isLoadingPoNumber = useSelector(getSelectedPoNumberIsLoading);
    const currentFilter = useSelector(getProposalFilter);
    const catalogItems = useSelector(getCatalogItems);
    const sellersInProposals = useSelector(getProposalFilterSellersData);

    const allowedStatuses = ['DRAFT', 'IN_REVIEW'];
    const isAllowedToEditPO: Boolean =
        (allowedStatuses
            .concat(['PENDING_APPROVAL'])
            .includes(currentOrder.status) &&
            !isSupervisor) ||
        (allowedStatuses.includes(currentOrder.status) && isSupervisor);

    const items = catalogItems as CatalogItem[];

    const styleForButtons = {
        '& span': {
            margin: 0,
        },
        padding: '5px',
        minWidth: 'auto',
    };

    useUnmountEffect(() => {
        dispatch(orderProposalFilterActions.setFilter('All'));
    });

    const isRequiredPoInProperty = useMemo(
        () => !!currentProperty?.isPoNumbersRequired,
        [currentProperty],
    );

    const closeEditPoMode = () => {
        setEditPoProposalId('');
        setTempPoValue('');
    };

    const handleApplyPoNumber = useCallback(
        async (supplierId: string) => {
            setEditPoProposalId('');
            await dispatch(
                editPurchaseOrderNumber({
                    orderId: currentOrder.id,
                    supplierId,
                    poNumber: tempPoValue,
                }),
            );
        },
        [currentOrder, dispatch, tempPoValue],
    );

    const preventPropagation: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
    };

    const groupBySellerName = () => {
        const newData: Record<
            string,
            (SelectedProposal & { itemId: string })[]
        > = {};
        const poNumbers = currentOrder.poNumbers;
        Object.entries(sellersInProposals).forEach(([itemId, p]) => {
            const sellerUid = p.sellerUid;
            const matchingPo = poNumbers.find((el) => el.id === sellerUid);
            if (!newData?.[p.name]) {
                newData[p.name] = [];
            }
            const poNumberForSupplier = matchingPo
                ? { poNumber: matchingPo.poNumber }
                : {};

            newData[p.name].push({ ...p, ...poNumberForSupplier, itemId });
        });

        return newData;
    };

    const grouped = groupBySellerName();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        e.stopPropagation();
        e.preventDefault();

        dispatch(orderProposalFilterActions.setFilter(name));

        const filteredCatalogItems = items.filter((i) => {
            return (
                'selectedProposal' in i &&
                grouped?.[name]?.some((p) => p.itemId == i.id)
            );
        });

        dispatch(
            orderProposalFilterActions.setFilteredItems(filteredCatalogItems),
        );
    };
    const isEmptyProposalsList = useMemo(() => {
        const proposalsArray = Object.entries(grouped);
        return (
            proposalsArray?.length <= 1 &&
            proposalsArray[0] &&
            proposalsArray[0][0] === 'null'
        );
    }, [grouped]);

    return (
        !isEmptyProposalsList && (
            <Box>
                <Box display="flex" alignItems="center">
                    <Radio
                        checked={currentFilter === 'All'}
                        onChange={(e) => onChange(e, 'All')}
                        onClick={preventPropagation}
                        value={'All'}
                    />
                    <Typography
                        typography="openSans.subtitle1Medium"
                        sx={(theme) => ({
                            color: alpha(theme.palette.common?.black, 0.5),
                        })}
                    >
                        {t('Select All')}
                    </Typography>
                </Box>
                <Box gap="8px" display="flex" flexDirection="column">
                    {Object.entries(grouped)?.map(([name, proposals]) => {
                        return (
                            name !== 'null' && (
                                <Box
                                    data-testclass="order-summary-seller"
                                    display="flex"
                                    flexDirection="column"
                                    width="100%"
                                    gap="2px"
                                    sx={{
                                        bgcolor: 'grey.500',
                                        borderRadius: '4px',
                                        padding: '9px',
                                    }}
                                    key={name}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Radio
                                                sx={{ padding: 0 }}
                                                checked={currentFilter === name}
                                                onChange={(e) =>
                                                    onChange(e, name)
                                                }
                                                onClick={preventPropagation}
                                                value={name}
                                            />
                                            <Typography
                                                data-testclass="order-summary-seller-name"
                                                typography="openSans.subtitle1Medium"
                                                sx={(theme) => ({
                                                    marginLeft: '9px',
                                                    marginRight: '4px',
                                                    color: alpha(
                                                        theme.palette.common
                                                            ?.black,
                                                        0.85,
                                                    ),
                                                })}
                                            >
                                                {name}
                                            </Typography>
                                            <Typography
                                                mr="4px"
                                                data-testclass="order-summary-seller-qty"
                                            >
                                                ({proposals.length})
                                            </Typography>
                                        </Box>
                                        <Typography
                                            mr="5px"
                                            data-testclass="order-summary-seller-price"
                                        >
                                            {getPrice(
                                                proposals.reduce(
                                                    (acc, proposal) =>
                                                        acc +
                                                        Number(proposal.price),
                                                    0,
                                                ),
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box
                                        data-testclass="order-summary-seller-po"
                                        display="flex"
                                        justifyContent="space-between"
                                        width="100%"
                                        style={{ alignItems: 'center' }}
                                    >
                                        <Box style={{ flex: 1 }}>
                                            <Box
                                                display="flex"
                                                style={{ width: '100%' }}
                                                sx={{ paddingLeft: '33px' }}
                                                alignItems="center"
                                            >
                                                <Typography
                                                    typography="openSans.subtitle1"
                                                    title="Purchase Order"
                                                    display="flex"
                                                >
                                                    {t('PO')}
                                                    {isRequiredPoInProperty && (
                                                        <Typography
                                                            component="span"
                                                            style={{
                                                                color: '#BF1B28',
                                                                lineHeight: 1,
                                                            }}
                                                        >{`*`}</Typography>
                                                    )}
                                                </Typography>
                                                {editPoProposalId &&
                                                editPoProposalId ===
                                                    proposals[0].sellerUid ? (
                                                    <TextField
                                                        sx={{
                                                            paddingLeft: '3px',
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            maxWidth: '250px',
                                                        }}
                                                        autoFocus
                                                        value={tempPoValue}
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.value
                                                                    .length ===
                                                                17
                                                            )
                                                                return;
                                                            setTempPoValue(
                                                                e.target.value,
                                                            );
                                                        }}
                                                        InputProps={{
                                                            sx: {
                                                                fontSize:
                                                                    '14px',
                                                                height: '22px',
                                                                background:
                                                                    '#fff',
                                                                borderRadius:
                                                                    '4px',
                                                            },
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        {!isLoadingPoNumber ? (
                                                            <>
                                                                {proposals[0]
                                                                    ?.poNumber ? (
                                                                    <Typography
                                                                        typography="openSans.subtitle1"
                                                                        paddingLeft="3px"
                                                                        color="#000"
                                                                    >
                                                                        {
                                                                            proposals[0]
                                                                                .poNumber
                                                                        }
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography
                                                                        typography="openSans.subtitle1"
                                                                        paddingLeft="3px"
                                                                        color="#0000004D"
                                                                    >
                                                                        {t(
                                                                            'not specified',
                                                                        )}
                                                                    </Typography>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Skeleton
                                                                sx={{
                                                                    marginLeft:
                                                                        '10px',
                                                                }}
                                                                width="200px"
                                                                height="30px"
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        </Box>
                                        {editPoProposalId &&
                                        editPoProposalId ===
                                            proposals[0].sellerUid ? (
                                            <Box display="flex" gap="4px">
                                                <Button
                                                    sx={styleForButtons}
                                                    startIcon={
                                                        <Clear
                                                            sx={{
                                                                fontSize:
                                                                    '20px !important',
                                                            }}
                                                        />
                                                    }
                                                    onClick={closeEditPoMode}
                                                    data-testid="btn-cancel-edit-po"
                                                />
                                                <Button
                                                    sx={styleForButtons}
                                                    startIcon={
                                                        <CheckOutlined
                                                            sx={{
                                                                fontSize:
                                                                    '20px !important',
                                                            }}
                                                        />
                                                    }
                                                    data-testid="btn-save-po"
                                                    onClick={() => {
                                                        handleApplyPoNumber(
                                                            proposals[0]
                                                                .sellerUid,
                                                        );
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            isAllowedToEditPO && (
                                                <Button
                                                    sx={styleForButtons}
                                                    data-testid="btn-edit-po"
                                                    startIcon={
                                                        <ModeEditOutlineOutlined
                                                            sx={{
                                                                fontSize:
                                                                    '20px !important',
                                                            }}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        setTempPoValue(
                                                            proposals[0]
                                                                .poNumber || '',
                                                        );
                                                        setEditPoProposalId(
                                                            proposals[0]
                                                                .sellerUid,
                                                        );
                                                    }}
                                                />
                                            )
                                        )}
                                    </Box>
                                </Box>
                            )
                        );
                    })}
                </Box>
            </Box>
        )
    );
});
