import {
    CatalogItem,
    MappedCatalogItem,
} from 'entities/CatalogItem/model/types/CatalogItemSchema';

export type SummaryByCategories = Record<
    string,
    {
        count: number;
        cost: string;
    }
>;

export const getGroupSummary = (
    groupedItems: Record<string, CatalogItem[]>,
    selectedItems: Record<string, Partial<MappedCatalogItem>>,
) => {
    const result: Record<
        string,
        {
            count: number;
            cost: number;
        }
    > = {};
    Object.entries(groupedItems).forEach(([categoryId, items]) => {
        let count = items.length;
        let totalCost = 0;

        items.forEach((item) => {
            const pricePerItem = item?.selectedProposal?.price || item?.price;

            totalCost += Number(pricePerItem) * selectedItems?.[item.id]?.qty;
        });

        result[categoryId] = {
            count,
            cost: totalCost,
        };
    });
    return result;
};
