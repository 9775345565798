import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { ChangeOrderStatusButton } from './ChangeOrderStatusButton';

interface PlaceOrderButtonProps {
    orderId: string;
    isNoItemsInOrder: boolean;
}

export const MoveToApprovalOrderButton = memo(
    ({ orderId, isNoItemsInOrder }: PlaceOrderButtonProps) => {
        const { t } = useTranslation();

        return (
            <ChangeOrderStatusButton
                newStatus={OrderStatuses.IN_REVIEW}
                orderId={orderId}
                messageOnSuccess={t('Order moved to Supervisor Review')}
                variant="contained"
                disabled={isNoItemsInOrder}
            >
                {t('Send for review')}
            </ChangeOrderStatusButton>
        );
    },
);
