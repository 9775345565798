import { MouseEventHandler, memo, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import {
    IndeterminateCheckBoxOutlined,
    AddBox,
    Add,
    Remove,
    DeleteOutline,
} from '@mui/icons-material';
import { maxAllowedItemsInOrder } from 'entities/CatalogItem/model/consts/catalogItemsConsts';
import { useSelector } from 'react-redux';
import {
    getUserCanAddOrderItem,
    getUserCanChangeOrderItem,
    getUserCanDeleteOrderItem,
} from 'entities/User/model/selectors/userPermissionsSeletors';

import {
    getCanEditOwnSelectedOrder,
    getCanEditSelectedOrder,
} from 'entities/Order/model/selectors/ordersPermissions';
import { getSelectedOrderStatus } from 'entities/Order/model/selectors/ordersSelectors';

interface AddCatalogItemWithQtyProps {
    active: boolean;
    minimized?: boolean;
    deleteDisabled?: boolean;
    value: number;
    loading: boolean;
    hideSwitcher?: boolean;
    onSetValue: (value: number) => void;
    onItemToggleSelect: () => void;
    isNoGap?: boolean;
}

const iconStyles = {
    width: '40px',
    height: '40px',
};

const buttonStyles = {
    width: '38px',
    minWidth: '38px',
    fontSize: '16px',
    color: 'grey.600',
};

const countStyles = {
    ...buttonStyles,
    color: 'black',
};

export const AddCatalogItemWithQty = memo(
    ({
        active,
        minimized,
        hideSwitcher,
        deleteDisabled,
        loading,
        value,
        onSetValue,
        onItemToggleSelect,
        isNoGap,
    }: AddCatalogItemWithQtyProps) => {
        const canEditSelectedOrder = useSelector(getCanEditSelectedOrder);
        const canAddOrderItem = useSelector(getUserCanAddOrderItem);
        const canDeleteOrderItem = useSelector(getUserCanDeleteOrderItem);
        const canChangeOrderItem = useSelector(getUserCanChangeOrderItem);
        const currentOrderStatus = useSelector(getSelectedOrderStatus);

        const canEditOwnSelectedOrder = useSelector(getCanEditOwnSelectedOrder);

        const canDoAction = (externalPermission: boolean) => {
            const canEditWithoutPermission =
                !currentOrderStatus || canEditOwnSelectedOrder;

            return (
                (canEditSelectedOrder && externalPermission) ||
                canEditWithoutPermission
            );
        };

        const canAdd = canDoAction(canAddOrderItem);
        const canDelete = canDoAction(canDeleteOrderItem);
        const canChange = canDoAction(canChangeOrderItem);

        const preventDefaultContainerClick: MouseEventHandler<
            HTMLDivElement
        > = (e) => {
            e.stopPropagation();
        };

        const onIncrease = () => {
            const futureValue = value + 1;
            if (futureValue <= maxAllowedItemsInOrder) {
                onSetValue(futureValue);
            }
        };

        const onDecrease = () => {
            onSetValue(value - 1);
        };

        const toggleSelect = useCallback(() => {
            if (!loading) {
                onItemToggleSelect();
            }
        }, [loading, onItemToggleSelect]);

        const getCheckboxIcon = () => {
            if (canAdd && !active) {
                return <AddBox sx={{ ...iconStyles }} />;
            } else if (canDelete && active) {
                return (
                    <IndeterminateCheckBoxOutlined
                        sx={{
                            ...iconStyles,
                            color: 'primary.main',
                        }}
                    />
                );
            }
        };

        const buttonIsDisplayed = () => {
            if (hideSwitcher) {
                return false;
            }

            if (canAdd && !active) {
                return true;
            } else if (canDelete && active) {
                return true;
            }
            return false;
        };

        return (
            <Box
                display="flex"
                flexDirection={minimized ? 'row-reverse' : 'column'}
                gap={minimized && !isNoGap ? '12px' : '0px'}
                justifyContent="space-between"
            >
                {buttonIsDisplayed() ? (
                    <Box
                        onClick={preventDefaultContainerClick}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Button
                            data-testclass="catalog-item-select"
                            size="small"
                            onClick={toggleSelect}
                            disabled={loading}
                            sx={{
                                padding: '0',
                                minWidth: 'auto',
                                mr: '-12px',
                            }}
                            startIcon={getCheckboxIcon()}
                        />
                    </Box>
                ) : (
                    <Box />
                )}

                {active ? (
                    <Box
                        onClick={preventDefaultContainerClick}
                        display="flex"
                        borderRadius="4px"
                        bgcolor="#f4f4f4"
                        width={canChange || canDelete ? 'auto' : '132px'}
                        height="32px"
                        justifyContent="center"
                        data-testclass="order-item-qty-controls"
                    >
                        {value === 1 && canDelete && !deleteDisabled && (
                            <Button
                                disabled={loading}
                                onClick={onDecrease}
                                sx={{ ...buttonStyles }}
                                data-testclass="order-item-remove"
                            >
                                <DeleteOutline />
                            </Button>
                        )}
                        {value > 1 && canChange && (
                            <Button
                                disabled={loading}
                                onClick={onDecrease}
                                sx={{ ...buttonStyles }}
                                data-testclass="order-item-decrease-qty"
                            >
                                <Remove />
                            </Button>
                        )}

                        <div
                            style={{
                                ...countStyles,
                                textAlign: 'center',
                                lineHeight: '32px',
                                fontWeight: 600,
                            }}
                            data-testclass="order-item-qty"
                        >
                            {value}
                        </div>
                        {canChange && (
                            <Button
                                disabled={
                                    maxAllowedItemsInOrder <= value || loading
                                }
                                onClick={onIncrease}
                                data-testclass="order-item-increase-qty"
                                sx={{ ...buttonStyles }}
                            >
                                {maxAllowedItemsInOrder >= value && <Add />}
                            </Button>
                        )}
                    </Box>
                ) : null}
            </Box>
        );
    },
);
