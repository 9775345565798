import { useTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    Box,
    Typography,
    alpha,
} from '@mui/material';
import { AccordionHeader } from 'shared/ui/AccordionHeader/AccordionHeader';
import { ShipmentSchema } from 'entities/Order/model/types/OrderSchema';

export const OrderShipmentDetails = ({
    shipmentStatus,
}: {
    shipmentStatus: ShipmentSchema[];
}) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ my: '16px' }}>
            <Accordion
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.grey[500], 0.5),
                    borderRadius: '12px',
                })}
                defaultExpanded
            >
                <AccordionHeader title={t('Shipment details')} />
                <AccordionDetails sx={{ p: '0 16px 12px' }}>
                    {shipmentStatus.map((shipment, index) => (
                        <Box key={index} sx={{ mb: '16px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    mt: '4px',
                                    mb: '8px',
                                }}
                            >
                                {shipment.seller || 'Ferguson'}
                            </Typography>
                            <Box sx={{ paddingLeft: '20px' }}>
                                {shipment.deliDeliverAt && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: '8px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '15px',
                                                fontWeight: 400,
                                            }}
                                        >
                                            {t('Delivery date')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '15px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {shipment.deliDeliverAt}
                                        </Typography>
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: '8px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '15px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {t('Carrier')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '15px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {shipment.trackingInfo?.carrier}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: '8px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '15px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {t('Tracking')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '15px',
                                            fontWeight: 600,
                                            color: '#3F6EB7',
                                        }}
                                    >
                                        {shipment.trackingInfo?.trackingNumber}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
