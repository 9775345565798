/* eslint-disable max-len */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { DialogBoxContent } from '../DialogBoxContent/DialogBoxContent';

interface BlockPurchaseOrderModalProps {
    showBlockModal: boolean;
    onCloseBlockModal: () => void;
}

export default memo(
    ({ showBlockModal, onCloseBlockModal }: BlockPurchaseOrderModalProps) => {
        const { t } = useTranslation();

        return (
            showBlockModal && (
                <Dialog open onClose={onCloseBlockModal}>
                    <DialogBoxContent
                        onClose={onCloseBlockModal}
                        buttons={
                            <Box
                                display="flex"
                                justifyContent="end"
                                width="100%"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onCloseBlockModal}
                                >
                                    {t('Ok')}
                                </Button>
                            </Box>
                        }
                    >
                        <Box maxWidth="360px">
                            <Typography
                                typography={'poppins.subtitle2'}
                                mb="18px"
                            >
                                {t('Missing Purchase Order(s)')}
                            </Typography>
                            <Typography typography={'openSans.body2'} mb="18px">
                                {t(
                                    'Please be sure a purchase order has been provided for all suppliers before proceeding with the order.',
                                )}
                            </Typography>
                        </Box>
                    </DialogBoxContent>
                </Dialog>
            )
        );
    },
);
