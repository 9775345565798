import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { FetchMetaResponse } from '../../types/OrderSchema';

export const fetchMetaInfo = createAsyncThunk<
    FetchMetaResponse,
    { id: string },
    ThunkConfig<string>
>('order/fetchMetaInfo', async ({ id }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<FetchMetaResponse>(
            `/v1/orders/${id}/meta`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
