import { Box } from '@mui/material';
import {
    getCatalogItems,
    getCatalogItemsSelectedIsEmpty,
    getCatalogItemsShowDetailedView,
    getSelectedCatalogItem,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { useSelector } from 'react-redux';
import { ReactNode, useMemo } from 'react';
import { PropertyCatalogEmpty } from './PropertyCatalogEmpty';
import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog';
import { getSelectCategoryId } from 'entities/Category/model/selectors/categorySelectors';
import { Outlet, useLocation } from 'react-router-dom';
import { getAddCatalogItemMode } from 'features/AddCatalogItem/model/selectors/addCatalogItemSelectors';
import { MoveToInventoryDialog } from 'features/moveToInventoryCatalogItem/ui/MoveToInventoryDialog/MoveToInventoryDialog';
import { CatalogItemsSelectedCounter } from 'entities/CatalogItem';

type PropertyCatalogItemsProps = {
    showCatalogItems: boolean;
    hideActionPanel?: boolean;
    moveTo?: PropertyCatalogItemsMoveTo;
    actionButtons: ReactNode;
};

export const PropertyCatalogItems = ({
    showCatalogItems,
    hideActionPanel,
    moveTo = PropertyCatalogItemsMoveTo.CATALOG,
    actionButtons,
}: PropertyCatalogItemsProps) => {
    const location = useLocation();

    const selectedCategory = useSelector(getSelectCategoryId);
    const catalogItems = useSelector(getCatalogItems);
    const hasCatalogItems = catalogItems && catalogItems?.length;
    const catalogItemsListSelectionIsEmpty = useSelector(
        getCatalogItemsSelectedIsEmpty,
    );
    const isEditingCatalog = useSelector(getAddCatalogItemMode);

    const detailedViewIsShown = useSelector(getCatalogItemsShowDetailedView);
    const selectedItem = useSelector(getSelectedCatalogItem);

    const moveToCatalogMode = moveTo === PropertyCatalogItemsMoveTo.CATALOG;
    const moveToInventory = moveTo === PropertyCatalogItemsMoveTo.INVENTORY;
    const isOrderEditing = location.state?.selectedOrderId;
    const showBulkPanel =
        moveToCatalogMode &&
        !catalogItemsListSelectionIsEmpty &&
        !isEditingCatalog;

    const selectedCategoryIsEmpty = Boolean(
        !hasCatalogItems && selectedCategory,
    );

    const itemDetailedOutletContext = useMemo(
        () => ({
            isDetailed: true,
            item: selectedItem,
            moveTo: moveTo,
            quickEdit: isOrderEditing,
        }),
        [isOrderEditing, moveTo, selectedItem],
    );

    const catalogItemsOutletContext = useMemo(
        () => ({
            hasCatalogItems,
            showBulkPanel,
            moveTo,
        }),
        [hasCatalogItems, moveTo, showBulkPanel],
    );

    return detailedViewIsShown && selectedItem ? (
        <Box p="16px 0 16px 16px" display="flex" height="100%">
            <Outlet context={itemDetailedOutletContext} />
        </Box>
    ) : (
        <>
            {showCatalogItems ? (
                <Outlet context={catalogItemsOutletContext} />
            ) : (
                <PropertyCatalogEmpty
                    selectedCategoryIsEmpty={selectedCategoryIsEmpty}
                />
            )}

            {hideActionPanel ? null : (
                <Box
                    display="flex"
                    flexDirection="column"
                    borderTop="1px solid"
                    borderColor="divider"
                    p="4px 16px 16px"
                >
                    <CatalogItemsSelectedCounter />
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        bgcolor="background.paper"
                        borderRadius={'0 0 4px 0'}
                        alignItems="flex-start"
                        gap="16px"
                    >
                        {actionButtons}
                    </Box>
                </Box>
            )}
            {!moveToInventory && <MoveToInventoryDialog />}
        </>
    );
};
