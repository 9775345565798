import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { getSelectedPropertyCatalogId } from 'entities/Property/model/selectors/PropertySelectors';
import { useSelector } from 'react-redux';
import { CategoryForm } from 'features/CategoryForm';
import { CategoryFormMode } from 'features/CategoryForm/module/types/categoryFormsTypes';

export const AddPropertyCatalogCategoryAtList = memo(() => {
    const { t } = useTranslation();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const catalogId = useSelector(getSelectedPropertyCatalogId);

    const openInput = useCallback(() => {
        setIsEditMode(true);
    }, []);

    const closeInput = useCallback(() => {
        setIsEditMode(false);
    }, []);

    return (
        <Box>
            <Box
                alignItems="center"
                sx={{ cursor: 'pointer' }}
                onClick={openInput}
            >
                <Button
                    data-testid="Add-new-category-button"
                    sx={{ padding: 0 }}
                >
                    <AddCircleOutline
                        color="primary"
                        sx={{ width: '24px', height: '24px' }}
                    />
                    <Typography
                        color="primary"
                        ml="8px"
                        typography="openSans.body2"
                    >
                        {t('Add new category')}
                    </Typography>
                </Button>
            </Box>

            {isEditMode && (
                <CategoryForm
                    mode={CategoryFormMode.CREATE}
                    catalogId={catalogId}
                    onClose={closeInput}
                    formTitle={t('New Category')}
                />
            )}
        </Box>
    );
});
