export const convertDate = (dateStr: string) => {
    const date = new Date(dateStr);

    const day = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });

    const time = date.toLocaleTimeString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
    });

    return {
        day: day === 'Invalid Date' ? '' : day,
        time: time === 'Invalid Date' ? '' : time,
    };
};

export const isToday = (date: string) => {
    const today = new Date();
    const currentDate = new Date(date);

    if (!currentDate) {
        return false;
    }

    return (
        currentDate.getDate() == today.getDate() &&
        currentDate.getMonth() == today.getMonth() &&
        currentDate.getFullYear() == today.getFullYear()
    );
};

export const convertReadableFormatDate = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('en', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    });
    return formatter.format(date);
};

export const convertFormatDateForReportsRequest = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};
