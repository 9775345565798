export enum UserRole {
    REOPS_ADMIN = 'ReOps Admin',
    PMC_ADMIN = 'PMC Admin',
    PROPERTY_MANAGER = 'Property Manager',
    MAINTENANCE_SUPERVISOR = 'Maintenance Supervisor',
    MAINTENANCE_TECHNICIAN = 'Maintenance Technician',
    TEST_NOT_EXISTING_ROLE = 'Not Existing role for tests',
}

export enum UserStatus {
    INVITED = 'Invited',
    SUSPENDED = 'Suspended',
    UNSUSPENDED = 'Unsuspended',
}

export enum UserPermissions {
    // Catalog
    EDIT_PROPERTY_CATALOG = 'API_EDIT_PROPERTY_CATALOG',
    API_DOWNLOAD_LABELS = 'API_DOWNLOAD_LABELS',

    // Order item
    ADD_ORDER_ITEM = 'add_orderitem',
    VIEW_ORDER_ITEM = 'view_orderitem',
    DELETE_ORDER_ITEM = 'delete_orderitem',
    CHANGE_ORDER_ITEM = 'change_orderitem',
    CAN_SEE_PRICES = 'API_CAN_SEE_PRICES',

    //Order
    EDIT_PENDING_APPROVAL_ORDER = 'API_EDIT_PENDING_APPROVAL_ORDER',
    EDIT_IN_REVIEW_ORDER = 'API_EDIT_IN_REVIEW_ORDER',
    REJECT_ORDER = 'API_REJECT_ORDER',
    APPROVE_ORDER = 'API_APPROVE_ORDER',
    ADD_ORDER = 'add_order',
    MOVE_ORDER_TO_PENDING_APPROVAL = 'API_TO_PENDING_APPROVAL_ORDER',
}

export const acceptableRolesForApp = [
    UserRole.PMC_ADMIN,
    UserRole.PROPERTY_MANAGER,
    UserRole.MAINTENANCE_TECHNICIAN,
    UserRole.MAINTENANCE_SUPERVISOR,
];
