import { useTranslation } from 'react-i18next';
import { memo, useCallback, useState } from 'react';
import { Box, Dialog, Button } from '@mui/material';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { CatalogItemInput } from 'entities/CatalogItem/ui/CatalogItemInput/CatalogItemInput';
import { createCategoryService } from 'entities/Category/model/services/createCategory/createCategoryService';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { object, string } from 'yup';
import { changeCategoryService } from 'entities/Category/model/services/editCategory/changeCategoryService';
import { CategoryFormMode } from 'features/CategoryForm/module/types/categoryFormsTypes';
import { convertKeysToCamelCase } from 'shared/lib/convertKeysToCamelCase/convertKeysToCamelCase';

interface CategoryFormProps {
    mode: CategoryFormMode;
    color?: string;
    glCode?: string;
    title?: string;
    formTitle?: string;
    catalogId: string;
    categoryId?: string;
    onClose: () => void;
}

export interface CategoryItemFormValues {
    color: string;
    title: string;
    glCode: string;
    categoryId?: string;
}

const validationSchema = object({
    title: string().required('Please, enter the name for the category'),
    color: string().required('Please, select the color for the category'),
    glCode: string()
        .min(4, 'Gl code has at least 4 characters')
        .max(8, 'Gl code has at most 8 characters'),
});

export const CategoryForm = memo(
    ({
        title,
        formTitle,
        glCode,
        color,
        catalogId,
        categoryId,
        mode,
        onClose,
    }: CategoryFormProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();

        const [loading, setLoading] = useState(false);

        const onCategoryCreate = useCallback(
            async ({ color, title, glCode }: CategoryItemFormValues) => {
                return await dispatch(
                    createCategoryService({
                        color: color,
                        title,
                        glCode,
                        catalogId,
                    }),
                );
            },
            [catalogId, dispatch],
        );

        const onCategoryChange = useCallback(
            async ({
                color,
                title,
                glCode,
                categoryId,
            }: CategoryItemFormValues) => {
                return await dispatch(
                    changeCategoryService({
                        color: color,
                        title,
                        catalogId,
                        glCode,
                        categoryId,
                    }),
                );
            },
            [catalogId, dispatch],
        );

        const close = () => {
            onClose();
        };

        const onSubmit = (props: FormikProps<CategoryItemFormValues>) => {
            props.submitForm();
        };

        const handleSubmit = async (
            values: CategoryItemFormValues,
            helpers: FormikHelpers<{ title: string }>,
        ): Promise<void> => {
            if (!loading) {
                setLoading(true);

                let response = null;
                const glCode = values?.glCode || null;

                if (mode === CategoryFormMode.CREATE) {
                    response = await onCategoryCreate({
                        ...values,
                        glCode,
                    });
                } else if (mode === CategoryFormMode.EDIT) {
                    response = await onCategoryChange({
                        ...values,
                        glCode,
                        categoryId,
                    });
                }

                if (response.meta.requestStatus === 'fulfilled') {
                    helpers.resetForm({ values });

                    onClose();
                }

                if (response.meta.requestStatus === 'rejected') {
                    if (typeof response.payload !== 'string') {
                        const errors = convertKeysToCamelCase(response.payload);
                        helpers.setErrors(errors);
                    }
                }
                setLoading(false);
            }
        };

        return (
            <Dialog
                sx={{
                    '& .MuiDialog-paper': { overflow: 'unset' },
                }}
                open
            >
                <Formik
                    initialValues={{ title, color, glCode }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<CategoryItemFormValues>) => (
                        <DialogBoxContent
                            onClose={close}
                            buttons={
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    width="100%"
                                >
                                    <Button
                                        data-testid="Button-cancel"
                                        onClick={close}
                                        color="primary"
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        data-testid="Button-save-add"
                                        variant="contained"
                                        disabled={
                                            !props.isValid || !props.dirty
                                        }
                                        onClick={() => onSubmit(props)}
                                    >
                                        {mode === CategoryFormMode.CREATE
                                            ? t('Add')
                                            : t('Save')}
                                    </Button>
                                </Box>
                            }
                        >
                            <Box maxWidth="380px" pb="24px">
                                <CatalogItemInput
                                    formikProps={props}
                                    loading={loading}
                                    formTitle={formTitle}
                                />
                            </Box>
                        </DialogBoxContent>
                    )}
                </Formik>
            </Dialog>
        );
    },
);
