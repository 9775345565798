import has from 'lodash/has';
import set from 'lodash/set';
import unset from 'lodash/unset';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import toNumber from 'lodash/toNumber';
import isObject from 'lodash/isObject';
import remove from 'lodash/remove';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import groupBy from 'lodash/groupBy';
import capitalize from 'lodash/capitalize';

function isEmail(value: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(value);
}

export {
    has,
    set,
    unset,
    isArray,
    camelCase,
    snakeCase,
    isEmpty,
    isEmail,
    debounce,
    toNumber,
    isObject,
    remove,
    find,
    kebabCase,
    startCase,
    groupBy,
    capitalize,
};
