/* eslint-disable max-len */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { Loader } from 'shared/ui/Loader/Loader';
import { DialogBoxContent } from '../DialogBoxContent/DialogBoxContent';

interface BlockRejectOrderModalProps {
    showBlockModal: boolean;
    isLoading: boolean;
    onCloseBlockModal: () => void;
    onSubmitModal: () => void;
}

export default memo(
    ({
        showBlockModal,
        onCloseBlockModal,
        onSubmitModal,
        isLoading,
    }: BlockRejectOrderModalProps) => {
        const { t } = useTranslation();

        return (
            showBlockModal && (
                <Dialog open onClose={onCloseBlockModal}>
                    <DialogBoxContent
                        onClose={onCloseBlockModal}
                        buttons={
                            <Box
                                display="flex"
                                justifyContent="end"
                                gap="8px"
                                width="100%"
                            >
                                <Button
                                    color="secondary"
                                    onClick={onCloseBlockModal}
                                >
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmitModal}
                                    disabled={isLoading}
                                    startIcon={
                                        isLoading && <Loader color="inherit" />
                                    }
                                >
                                    {t('Reject order')}
                                </Button>
                            </Box>
                        }
                    >
                        <Box maxWidth="360px">
                            <Typography
                                typography={'poppins.subtitle1'}
                                mb="18px"
                            >
                                {`Reject Order`}
                            </Typography>
                            <Typography typography={'openSans.body2'} mb="12px">
                                {`Are you sure you want to reject this order? This action cannot be undone.`}
                            </Typography>
                        </Box>
                    </DialogBoxContent>
                </Dialog>
            )
        );
    },
);
