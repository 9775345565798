import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { VendorInfoSchema, VendorInfoResponse } from '../types/VendorsSchema';

export const updateVendorInfo = createAsyncThunk<
    VendorInfoResponse,
    {
        vendorId: string;
        propertyId: string;
        accountManager: VendorInfoSchema;
    },
    ThunkConfig<string>
>(
    'vendors/updateVendorInfo',
    async ({ propertyId, vendorId, accountManager }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch<VendorInfoResponse>(
                `/v1/properties/${propertyId}/vendors/${vendorId}`,
                {
                    accountManager,
                },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
