import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const applySettingsToProperty = createAsyncThunk<
    { message: string },
    {
        mode: string;
        preferredSuppliersIds: string[];
        propertyId: string;
        orderType: string;
    },
    ThunkConfig<string>
>(
    'applySettingsToProperty',
    async (
        { mode, preferredSuppliersIds, propertyId, orderType },
        thunkApi,
    ) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post<{ message: string }>(
                `/v1/product_sourcing/settings/${propertyId}`,
                { mode, preferredSuppliersIds, orderType },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
