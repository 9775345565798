import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { VendorsResponseSchema } from '../types/VendorsSchema';

export const deleteVendorFromProperty = createAsyncThunk<
    VendorsResponseSchema,
    { vendorId: string; propertyId: string },
    ThunkConfig<string>
>(
    'vendors/deleteVendorFromProperty',
    async ({ propertyId, vendorId }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.delete(
                `/v1/properties/${propertyId}/vendors`,
                { data: { vendorId } },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
