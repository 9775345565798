import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from '@mui/material';
import {
    getRouteGlobalCatalog,
    getRouteGlobalCatalogItem,
    getRouteOrderCatalogCategoriesItem,
    getRoutePropertiesCatalogItem,
} from 'shared/const/router';
import { useMatch, useNavigate } from 'react-router-dom';

import { ArrowDropDown } from '@mui/icons-material';
import { CatalogItem } from 'entities/CatalogItem';
import { CatalogItemCheckbox } from 'features/CatalogItemCheckbox';
import { CatalogItemImage } from 'shared/ui/CatalogItemImage';
import { CatalogItemPrice } from './PropertyCatalogItemPrice';
import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog/models/consts/propertyCatalogItemsListTypes';
import { Proposal } from 'entities/Proposal/model/types/ProposalSchema';
import { SuppliersList } from 'shared/ui/SuppliersList';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { getPropertyCatalogId } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import { getSearchCatalogItemIsActive } from 'features/SearchCatalogItems/model/selectors/searchCatalogItemsSelectors';
import { getSelectCategoryId } from 'entities/Category/model/selectors/categorySelectors';
import { getSelectedOrderId } from 'entities/Order/model/selectors/ordersSelectors';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface PropertyCatalogItemProps {
    id: string;
    uid?: string;
    idToEdit?: string;
    isInOrder?: boolean;
    isOrderPage?: boolean;
    title: string;
    thumbnailUrl?: string;
    moveTo: PropertyCatalogItemsMoveTo;
    qty: number;
    isActive: boolean;
    sku: string;
    uom: string;
    qtyPerUom: number;
    minAllowedInStock: number;
    quickEdit?: boolean;
    categoryId?: string;
    isSearch?: boolean;
    isInventory: boolean;
    price: number;
    proposals: Proposal[];
    activatedAccounts: { [key: string]: boolean[] };
}

// enum OrderActionType {
//     ADD_ITEM = 'add',
//     DELETE_ITEM = 'delete',
//     EDIT_QTY_ITEM = 'edit',
// }

//TODO: Refactor
// Separate item by catalog items types:
// 1. Global catalog item
// 2. Property catalog item
//  2.1. Catalog item
//  2.2. Item in order

export const PropertyCatalogItem = ({
    id,
    uid,
    idToEdit,
    isInOrder,
    title,
    thumbnailUrl,
    moveTo,
    qty,
    isActive,
    isOrderPage,
    sku,
    uom,
    qtyPerUom,
    minAllowedInStock,
    quickEdit,
    categoryId,
    isInventory,
    price,
    isSearch,
    proposals,
    activatedAccounts,
}: PropertyCatalogItemProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectedCategoryId = useSelector(getSelectCategoryId);
    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const selectedOrderId = useSelector(getSelectedOrderId);
    const selectedPropertyCatalogId = useSelector(getPropertyCatalogId);
    const isCatalogItemsSearching = useSelector(getSearchCatalogItemIsActive);
    const canSeePrices = useSelector(getUserCanSeePrices);

    const isGlobalCatalog = useMatch(
        getRouteGlobalCatalog(':propertyId') + '/*',
    );

    const isMoveToOrderMode = moveTo === PropertyCatalogItemsMoveTo.ORDER;
    const isMoveToCatalogMode = moveTo === PropertyCatalogItemsMoveTo.CATALOG;
    const isViewOnly = moveTo === PropertyCatalogItemsMoveTo.VIEW_ONLY;
    const isMoveToInventoryMode =
        moveTo === PropertyCatalogItemsMoveTo.INVENTORY;

    const showCategoryLabel = isCatalogItemsSearching;
    const showPrice = (price || price === 0) && canSeePrices;

    const totalPrice =
        qty && (isOrderPage || isMoveToOrderMode) ? qty * price : 0;

    const itemLabel =
        (isMoveToInventoryMode ||
            isMoveToCatalogMode ||
            isMoveToOrderMode ||
            isInOrder) &&
        isInventory
            ? t('Inventory')
            : '';

    const expandDetailedView = useCallback(
        (id: string) => () => {
            dispatch(catalogItemActions.setSelectedItemById(id));
            dispatch(catalogItemActions.setShowDetailedView(true));

            if (isMoveToCatalogMode) {
                if (isSearch) return;

                if (isGlobalCatalog) {
                    navigate(
                        getRouteGlobalCatalogItem(
                            selectedPropertyId,
                            selectedCategoryId,
                            id,
                        ),
                    );
                } else {
                    navigate(
                        getRoutePropertiesCatalogItem(
                            selectedPropertyId,
                            selectedCategoryId,
                            id,
                        ),
                    );
                }
            } else {
                // for order view details
                navigate(getRouteOrderCatalogCategoriesItem(id), {
                    state: {
                        selectedOrderId,
                        selectedPropertyCatalogId,
                        selectedPropertyId,
                        fromOrder: selectedOrderId,
                    },
                });
            }
        },
        [
            dispatch,
            isGlobalCatalog,
            isMoveToCatalogMode,
            isSearch,
            navigate,
            selectedCategoryId,
            selectedOrderId,
            selectedPropertyCatalogId,
            selectedPropertyId,
        ],
    );

    return (
        <Box
            data-testclass="item-preview"
            sx={{
                borderBottom: '1px solid',
                borderColor: 'grey.500',
            }}
        >
            <Box
                key={title}
                sx={{
                    display: 'flex',
                    alingitems: 'flex-start',
                    flexDirection: 'column',
                    padding: '20px 0 4px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1',
                        gap: '16px',
                    }}
                >
                    <Box
                        sx={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '4px',
                        }}
                    >
                        <CatalogItemImage src={thumbnailUrl} />

                        {itemLabel && (
                            <Typography
                                data-testid="Item-inventory-mark"
                                typography="openSans.captionMedium"
                                color="primary.main"
                                align="center"
                            >
                                {itemLabel}
                            </Typography>
                        )}
                    </Box>
                    <Box flex="1 1">
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '12px',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid',
                                borderColor: 'grey.500',
                            }}
                        >
                            <Box
                                sx={isViewOnly ? {} : { cursor: 'pointer' }}
                                onClick={
                                    isViewOnly ? null : expandDetailedView(id)
                                }
                            >
                                <CatalogItem
                                    categoryId={
                                        showCategoryLabel ? categoryId : null
                                    }
                                    title={title}
                                    sku={sku}
                                    uom={uom}
                                    qtyPerUom={qtyPerUom}
                                    minAllowedInStock={minAllowedInStock}
                                    label={itemLabel}
                                />
                            </Box>
                            <Box display={isViewOnly ? 'none' : 'flex'}>
                                <Box height="100%">
                                    <CatalogItemCheckbox
                                        showQtyCheckbox={isMoveToOrderMode}
                                        isActive={isActive}
                                        qty={qty}
                                        itemIdToEdit={idToEdit}
                                        itemId={id}
                                        itemUid={uid}
                                        quickEdit={quickEdit}
                                        isInOrder={isInOrder}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            {showPrice && (
                                <CatalogItemPrice
                                    price={price}
                                    total={totalPrice}
                                    uom={uom}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
                {isGlobalCatalog && (
                    <Accordion
                        sx={{
                            width: '100%',
                            boxShadow: 'none',
                            padding: '4px 0 0 0',
                        }}
                    >
                        <AccordionSummary
                            sx={{ padding: 0, margin: 0 }}
                            expandIcon={<ArrowDropDown color="primary" />}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{ color: '#000000D9' }}
                                    typography="openSans.body2"
                                >
                                    {t('Suppliers ')} ({proposals?.length || 0})
                                </Typography>
                                <Typography
                                    typography="openSans.button"
                                    color="primary"
                                >
                                    {t('View')}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SuppliersList
                                proposals={proposals}
                                activatedAccounts={activatedAccounts}
                            />
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
        </Box>
    );
};
