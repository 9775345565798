import { StateSchema } from 'app/providers/StoreProvider';

export const getPropertyVendorsList = (state: StateSchema) =>
    state.vendors?.vendorsList;

export const getAllVendorsList = (state: StateSchema) =>
    state.vendors?.allVendorsList;

export const getaAllSpecialtiesList = (state: StateSchema) =>
    state.vendors?.allSpecialties;

export const getCurrentVendorToAdd = (state: StateSchema) =>
    state.vendors?.currentVendorToAdd;

export const getIsShowVendorToAddDialog = (state: StateSchema) =>
    state.vendors?.isShowVendorToAddDialog;

export const getIsLoadingVendorsList = (state: StateSchema) =>
    state.vendors?.dataIsLoading;
