const scrollStyles = `
    /* Webkit browsers (Chrome, Safari, and newer versions of Opera) */

    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #3F6EB7;
        border-radius: 4px;
    }

    /* Firefox and other browsers */
    .scrollbar {
        scrollbar-width: thin;
        scrollbar-color: #3F6EB7 transparent;
    }

    /* Handle */
    .scrollbar::-webkit-scrollbar-thumb {
        background-color: #3F6EB7;
        border-radius: 4px;
    }

    /* Track */
    .scrollbar::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* Corner */
    .scrollbar::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

export const Baseline = {
    styleOverrides: { root: scrollStyles },
};
