import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { SpecialtiesResponseSchema } from '../types/VendorsSchema';

export const fetchAllSpecialties = createAsyncThunk<
    SpecialtiesResponseSchema,
    null,
    ThunkConfig<string>
>('vendors/allListSpecialties', async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<SpecialtiesResponseSchema>(
            `/v1/vendors/specialities`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
