import { StateSchema } from 'app/providers/StoreProvider';

export const orderTableIsInitialLoaded = (state: StateSchema) =>
    state.orderTable.isInitialLoaded;

export const getOrders = (state: StateSchema) => state.orderTable.dataList;

export const getOrdersListIsLoading = (state: StateSchema) =>
    state.orderTable.listIsLoading;

export const getOrdersTablePagination = (state: StateSchema) =>
    state.orderTable.pagination;

export const getOrdersCurrentPage = (state: StateSchema) =>
    state.orderTable.pagination.currentPage;

export const getOrdersPageSize = (state: StateSchema) =>
    state.orderTable.pagination.pageSize;

export const getOrdersTableTotal = (state: StateSchema) =>
    state.orderTable.pagination.total;

export const getOrdersTableSorting = (state: StateSchema) =>
    state.orderTable.sorting;

export const getOrdersTableSortingColumnName = (state: StateSchema) =>
    state.orderTable.sorting.column;

export const getOrdersTableSortingDirection = (state: StateSchema) =>
    state.orderTable.sorting.direction;

export const getOrdersTableCounters = (state: StateSchema) =>
    state.orderTable.counters;

export const getOrdersTableCountersLoading = (state: StateSchema) =>
    state.orderTable.countersAreLoading;
